import styled from 'styled-components'

import { TEXT_MAX_WIDTH } from 'constants/dimensions'
import { Box, Flex, FlexProps } from 'ui/common'

export const LayoutTextSection: React.FC<FlexProps> = ({
  children,
  ...rest
}) => (
  <Flex justifyContent="center" {...rest}>
    <StyledBox
      maxWidth={{ _: '100%', tablet: TEXT_MAX_WIDTH }}
      px={{ _: '1rem', mobileL: '2rem', tablet: 0 }}
    >
      {children}
    </StyledBox>
  </Flex>
)

const StyledBox = styled(Box)`
  counter-reset: section;

  .colored {
    color: ${p => p.theme.colors.accent};
  }

  .bold {
    font-weight: bold;
  }
`
