import Layout from 'components/Layout/Layout'
import { LayoutTextSection } from 'components/Layout/LayoutTextSection'
import Link from 'components/Link/Link'

const NotFoundPage: React.FC = () => (
  <Layout>
    <LayoutTextSection>
      <h1>BŁĄD 404: Nie znaleziono strony</h1>
      <p>Strona o podanym adresie nie istnieje.</p>
      <p>
        Powrót na{` `}
        <Link to="/">stronę główną.</Link>
      </p>
    </LayoutTextSection>
  </Layout>
)

export default NotFoundPage
